.CardWrapper {
    @apply shadow-xl sm:rounded-2xl bg-white overflow-hidden;
    @apply flex flex-col;
}
.CardImg img {
    transition: transform 0.2s ease-in-out;
}
.CardImg:hover img {
    /*transform: scale(1.15); !* 根据需要调整放大的比例 *!*/
}
.SkeletonWrap {
    @apply overflow-hidden;
}
.SkeletonWrap {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 10;
}

.SkeletonWrap > :global(img) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.CardContent {
    @apply px-8 pt-6 pb-4;
    @apply flex-1;
    @apply border-b;
}
.Date {
    @apply flex items-baseline mb-4 text-gray-600;
}
.ReadMore {
    @apply px-8 py-6;
}
.ReadMoreBtn {
    /*@apply inline-block border-2 border-gray-900 text-gray-900 hover:bg-black hover:text-white px-4 py-2 rounded transition duration-300 font-bold;*/
    @apply font-bold uppercase block;
}
